// Import google font: Poppins
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Colors Variables
$white: #ffffff;
$black: #000000;
// $bg1: #17082A;
// $bg2: #210F37;

// $orange: #F79E44;
// $pink: #E71D8B;
// $purple: #6644B8;
// $purple2: #372562;
// $ltpurple: #BCB7C3;

$orange: #F79E44;
$pink: #c87e00;
$ltpink: #edae42;
$purple: #5244b8;
$purple2: #2a2562;
$ltpurple: #b8b7c3;


$blue: #004a77;
$ltblue: #dfdfdf;
$bg1: #0e082a;
$bg2: #120f37;
$Primary1: #004a77; //(matches your logo)
$Primary2: #336699; //(darkens primary 1 for contrast)
$Primary3: #668cb3; //(lightens primary 1 for accents)

$imagePosters: "https://th.bing.com/th/id/OIG2.pRCYbaUSRbvBNtoiMd12?w=1024&h=1024&rs=1&pid=ImgDetMain";
// $imagePosters: "https://cdn.shopify.com/s/files/1/0607/5056/1539/articles/netflix.jpg?v=1669877376";
// $imagePosters: "https://th.bing.com/th/id/OIG3.a6Cqa12ffUL0QFbqvxyr?pid=ImgGn";

// Global default
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  color: $white;
  background-color: $bg1;
}

// Branding Logo
.nav-logo {
  height: 50px;
  width: 150px;
  object-fit: contain;
}
.footer-logo {
  width: 150px;
  object-fit: contain;
  margin-bottom: 30px;
}

// Custom Bootstrap 
.btn.btn-primary {
  background-color: $pink;
  border-color: $pink;
}
.navbar.bg-dark {
  background-color: $bg1 !important;
}
.btn.btn-outline {
  background-color: transparent;
  border: 1px solid $pink;
}
.breadcrumb, .breadcrumb .breadcrumb-item a {
  font-size: 14px;
  color: $pink;
}
.breadcrumb .breadcrumb-item.active {
  font-size: 14px;
  color: $white;
}
.breadcrumb .breadcrumb-item::before{
  color: $pink;
}